<template>
    <v-container fluid grid-list-xl>
        <v-layout>
            <div class="d-flex status-board">
                <BoardList
                        v-for="list in lists"
                        v-bind:key="list.id"
                        :title="list.name"
                        :cards="cards[list.id]"
                        :listheight="boardheight"
                >
                </BoardList>
            </div>
        </v-layout>
    </v-container>
</template>

<script>

    // import BoardList from "./BoardList";
    const BoardList = () => import("./BoardList");

    export default {
        name: "StatusBoard",
        components: {BoardList},
        data(){
            return {
            }
        },
        // props: {
        //     lists: Array,
        //     cards: Object,
        //     boardheight: Number
        // },
        props: ['lists','cards','boardheight'],
        methods: {

        }
    }
</script>

<style scoped>
    .status-board {
        overflow-x: scroll;
        overflow-y: hidden;
    }
</style>