<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pt-3 pb-0 px-3>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import { api } from "../../../api";
    import json from "../../../assets/exportStatusBoardData"
    import StatusBoard from "../../../components/Appic/StatusBoard";

    export default {
        name: "ExportBoard",
        components: {StatusBoard},
        data() {
            return {
                loader: true,
                tmp: "",
                board: {},
                boardheight: 0
            }
        },
        mounted(){
            this.loader = false;
            this.handleResize()
        },
        methods: {
            getStatusBoardData() {
                api
                    .get("/boards/export")
                    .then(response => {
                        this.loader = false;
                        this.board = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            handleResize() {
                this.boardheight = window.innerHeight - (130);
            },
        },
        created() {
            this.getStatusBoardData()
            window.addEventListener('resize', this.handleResize)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        }
    }
</script>

<style scoped>

</style>